<template>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
  <div v-show="showScrollButton" class="w-[60px] flex items-center justify-center cursor-pointer flex-col min-h-[60px] p-[4px] bg-[var(--ci-primary-color)] border border-solid border-[#313843] rounded-xl fixed bottom-20 right-4" @click="scrollToTop">
     <div class="flex items-center justify-center w-[30px] h-[30px] mb-[2px] text-[12px] text-white rounded-full bg-[var(--ci-primary-color)]">
       <i class="flex items-center justify-center text-[var(--ci-secundary-color)] rounded-[50%] p-1">
           <span class="material-symbols-sharp">
           rocket
           </span>
       </i>
     </div>
    <div class="w-full [word-wrap:break-word] text-[12px] overflow-hidden text-center overflow-ellipsis align-middle font-medium" style="color: #68707b;">
   Topo
</div>
   </div>

   <span class="hidden material-symbols-sharp" style="display: none;">rocket</span>
   
  <div class="flex sm:hidden">
       <div class="fixed z-40 w-full h-16 max-w-lg -translate-x-1/2 bottom-0 left-1/2 navBtn" style="background-color: var(--ci-primary-color); color: var(--ci-gray-light);">
           <div class="grid h-full max-w-lg grid-cols-5 mx-auto">
               
             <button @click="handleButtonClick('home', homeIconUrl, '/')"
   type="button" 
   class="inline-flex flex-col items-center justify-center px-5 rounded-l-full group">
   <img :src="homeIconUrl" class="w-[25px]" />
   <span :style="{ color: selectedButton === 'home' ? '#f0c059' : '#68707B', fontSize: '12px' }">Início</span>
 </button>

               <!-- Botão Promoção -->
               <button @click="handleButtonClick('promotion', promotionIconUrl, '/home/events')" 
                       type="button" class="inline-flex flex-col items-center justify-center px-5 group">
                   <img :src="promotionIconUrl" class="w-[26px]" />
                   <span :style="{ color: selectedButton === 'promotion' ? '#f0c059' : '#68707B', fontSize: '12px' }">Promoção</span>
               </button>

               <!-- Botão Agente/Depósito -->
               <button v-if="isAuthenticated" @click="handleButtonClick('agent', agentIconUrl, '/home/agents')" 
                       type="button" class="inline-flex flex-col items-center justify-center px-5 rounded-r-full group">
                   <img :src="agentIconUrl" class="w-[25px]" />
                   <span :style="{ color: selectedButton === 'agent' ? '#f0c059' : '#68707B', fontSize: '12px' }">Agente</span>
               </button>

               <button v-else @click="handleButtonClick('deposit', depositIconUrl, '/home/agents')" 
                       type="button" class="inline-flex flex-col items-center justify-center px-5 rounded-r-full group">
                   <img :src="depositIconUrl" class="w-[25px]" />
                   <span :style="{ color: selectedButton === 'deposit' ? '#f0c059' : '#68707B', fontSize: '12px' }">Agente</span>
               </button>

               <!-- Botão Suporte -->
               <button @click="handleButtonClick('support', supportIconUrl, '/home/support')" 
                       type="button" class="inline-flex flex-col items-center justify-center px-5 rounded-r-full group">
                   <img :src="supportIconUrl" class="w-[25px]" />
                   <span :style="{ color: selectedButton === 'support' ? '#f0c059' : '#68707B', fontSize: '12px' }">Suporte</span>
               </button>

               <!-- Botão Perfil -->
               <button @click="handleButtonClick('profile', profileIconUrl, '/home/me')" 
                       type="button" class="inline-flex flex-col items-center justify-center px-5 group">
                   <img :src="profileIconUrl" class="w-[25px]" />
                   <span :style="{ color: selectedButton === 'profile' ? '#f0c059' : '#68707B', fontSize: '12px' }">Perfil</span>
               </button>
           </div>
       </div>
   </div>
</template>

<script>
import { useAuthStore } from "@/Stores/Auth.js";

export default {
 data() {
   return {
     selectedButton: '', // Botão selecionado
     showScrollButton: false, // Estado para mostrar o botão de rolagem
   };
 },
 
 computed: {
   isAuthenticated() {
     const authStore = useAuthStore();
     return authStore.isAuth;
   },

   // Computed properties para acessar os ícones com base no VITE_ASSET_BASE_URL
   homeIconUrl() {
     return `/storage/rox/icons/home2.png`;
   },
   promotionIconUrl() {
     return `/storage/rox/icons/promo2.png`;
   },
   agentIconUrl() {
     return `/storage/rox/icons/agent.png`;
   },
   depositIconUrl() {
     return `/storage/rox/icons/depo2.png`;
   },
   supportIconUrl() {
     return `/storage/rox/icons/suporte2.png`;
   },
   profileIconUrl() {
     return `/storage/rox/icons/perfil2.png`;
   }
 },

 mounted() {
   this.restoreButtonState(); // Restaura o estado do botão na montagem do componente
   window.addEventListener('scroll', this.handleScroll);
 },

 beforeDestroy() {
   window.removeEventListener('scroll', this.handleScroll);
 },

 methods: {
   handleButtonClick(button, newIcon, route) {
     this.updateButton(button, newIcon); // Atualiza o ícone e a cor do botão
     this.saveButtonState(button, newIcon); // Salva o estado do botão
     this.$router.push(route); // Faz o redirecionamento para a rota desejada
   },

   updateButton(button, newIcon) {
     this.selectedButton = button; // Atualiza o botão selecionado
   },

   saveButtonState(button, newIcon) {
     localStorage.setItem('selectedButton', button); // Salva o botão selecionado
   },

   restoreButtonState() {
     const savedButton = localStorage.getItem('selectedButton');
     if (savedButton) {
       this.selectedButton = savedButton; // Restaura o botão selecionado
     }
   },

   handleScroll() {
     this.showScrollButton = window.scrollY > 400;
   },

   scrollToTop() {
     const topElement = document.getElementById("顶部");
     if (topElement) {
       topElement.scrollIntoView({ behavior: "smooth" });
     }
   }
 }
};
</script>

<style scoped>
.fixed-button {
 position: fixed;
 bottom: 60px;
 right: 8px;
}
.material-symbols-sharp {
 font-variation-settings:
 'FILL' 1,
 'wght' 500,
 'GRAD' 0,
 'opsz' 24
}
</style>
