import { defineStore } from "pinia";
import { ref } from "vue";
import axios from 'axios';
import router from '../Router';
import HttpApi from "@/Services/HttpApi.js";

export const useSettingStore = defineStore("settingData", () => {
    const setting = ref(JSON.parse(localStorage.getItem('setting')));
    /**
     * Set Setting
     * @param settingValue
     */
    function setSetting(settingValue) {
        if(settingValue != null && settingValue.setting) {
            localStorage.setItem('setting', JSON.stringify(settingValue.setting));
            setting.value = settingValue.setting;
        }
    }

    function getSettingData()
    {
        return setting.value;
    }

    const setFavicon = (faviconUrl) => {
        let link = document.querySelector("link[rel~='icon']");
        if(!link){
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
            link.href = faviconUrl;
        }
    }
    /**
     * Check Token
     * @returns {Promise<undefined>}
     */
    async function getSetting() {
        try {
            const { data } = await HttpApi.get('/settings/data');
            //set title when loading
            document.title = data.setting.software_name ? data.setting.software_name : "IGames Chinesa" ;
            //set favicon
            let faviconUrl = "https://igames-assets.nyc3.cdn.digitaloceanspaces.com/" + data.setting.software_favicon
            setFavicon(faviconUrl);
            return data;
        } catch (error) {
            console.log(error.response);
        }
    }

    return {
        setting,
        setSetting,
        getSetting,
        getSettingData
    };
});
